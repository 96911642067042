<template>
  <div class="container">
    <section>
      <v-row class="d-flex align-center justify-center">
        <v-col cols="12" md="7">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Capela de Nossa Senhora Aparecida"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                Na região de Bento Rodrigues, também é relevante a Capelinha de
                Nossa Senhora Aparecida, que se caracteriza como um bem
                edificado contemporâneo, implantado em terreno ajardinado, em
                nível elevado em relação à estrada de terra, acessado por
                escadaria de concreto. Seu valor cultural está ligado à
                manifestação religiosa, como também se constitui como elemento
                característico de referência na paisagem.
              </p>
              <p style="text-align: center">
                <strong>Autora: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="70%"
            src="../../assets/sobre_cultura_bento_capela_04.png"
          ></v-img>
          <p style="font-size: 0.6em; width:70%; text-align: center;">
            Vista da Capela de Nossa Senhora Aparecida e da escadaria à frente,
            situada na estrada de acesso à comunidade atingida de Bento
            Rodrigues. Foto: Paula Zanardi, 2021.
          </p>
          <v-img
            max-width="70%"
            src="../../assets/sobre_cultura_bento_capela_05.png"
          ></v-img>
          <p style="font-size: 0.6em; width:70%; text-align: center;">
            Imagem de Nossa Senhora Aparecida no interior da Capela. Foto: Paula
            Zanardi, 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p class="mb-0">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <strong>Referências Bibliográficas</strong>
            </p>
            <p>
              CASTRIOTA, Leonardo Barci. Dossiê de Tombamento de Bento
              Rodrigues. UFMG. Belo Horizonte, 2019. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento"
                class="pink--text"
                >https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento</a
              >. Acesso em: 30 mar. 2022.
            </p>
            <p>
              PÓLEN Consultoria Patrimônio e Projetos Ltda. Diagnóstico
              preliminar dos bens culturais identificados no território atingido
              em Mariana pelo rompimento da barragem de Fundão. Mariana, 2017.
            </p>
            <p>
              TEIXEIRA, Vanessa Cerqueira. Fé e cultura barroca sob o manto
              mercedário: devoção, religiosidade e vivências a partir da
              irmandade de Nossa Senhora das Mercês de Mariana (1749-1822). 220
              f. 2017. Dissertação (Mestrado em História) — Instituto de
              Ciências Humanas, Universidade Federal de Juiz de Fora Juiz de
              Fora-MG, 2017.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
